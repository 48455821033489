/* @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@100;400;500&display=swap');
:root{
    /* --headingfont:'Quicksand', sans-serif;
	--font: 'PT Sans', sans-serif; */
    --headingfont: 'JetBrains Mono', monospace;
    --font: 'JetBrains Mono', monospace;
}
html, body{
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0);
}


.loading_screen {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgb(0, 0, 0);
    top: 0px;
    left: 0px;
    padding: 0px;
    margin: 0px;
    z-index: 1004;
}
  
.loading_screen_text{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 30;
    color: rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
    font-size: 11px;
    font-family: var(--font);
    font-weight: 800;
    
}



.webgl{
    z-index: -1;
    position: absolute;
    top: 0px;
    left: 0px;
    outline:none;
    /* width: 500px; */
    /* height: 50%; */
    padding: 0px; 
    margin: 0px;
    background-color: rgb(0, 0, 0);
    
}

.container{
    
    padding: 0px; 
    margin: 0px;
    
}
.point{
    position: absolute;
    top: 50%;
    left: 50%;
    /* border: 1px rgb(255, 255, 255) solid; */
      
}
.point:hover .text{
    /* opacity: 1; */
    
    
}

@media only screen and (max-width: 760px) {
    
    .point:hover .text{ 
        opacity: 0;
    }
    
  }

.point.visible .label{
    transform: scale(1,1);
}


.point .label {
position: absolute;
top: -20px;
left: -20px;


background-color: rgba(255, 0, 0, 0);


/* font-family: 'Times New Roman', Times, serif; */


margin: 0px;
padding: 0px;
text-align: center;
/* line-height: 20px; */
/* font-weight: bolder; */

pointer-events: auto;
transform: scale(0,0);
transition: transform 0.1s;
color: rgb(255, 255, 255);
/* border: 1px solid rgb(255, 255, 255); */


}


.point a {
    
    text-decoration:none;
    font-size: 20px;
    color: rgb(255, 255, 255);
    font-weight: 400;
    font-family: var(--font);
    margin: 0px;
    width: 5px;
    height: 5px;
    
  
    }
.point a:hover {
    
    text-decoration:none;
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-weight: bolder;
    
  
    }


.point .text{
    position: absolute;
    top:0px;
    
    /* width: 350px; */
    padding: 5px;
    
    background: rgba(0, 0, 0, 0);
    /* border: 1px solid rgb(0, 0, 0);  */
    
    line-height: 1.3em;
    font-family: 'PT Sans', sans-serif;
    
    font-weight: 100;
    font-size: 50px;
    opacity: 0;
    /* -webkit-transition: opacity 1s cubic-bezier(0.0, 0, 1.0, 0.0); */
    
    
    
    pointer-events: none;
    
}

.point:hover .text{
    position: absolute;
    top:0px;
    
    /* width: 350px; */
    padding: 5px;
    
    background: rgba(0, 0, 0, 0);
    /* border: 1px solid rgb(0, 0, 0);  */
    color: rgb(255, 255, 255);
    line-height: 1.3em;
    font-family: 'PT Sans', sans-serif;
    
    font-weight: 100;
    font-size: 14px;
    opacity: 1.0;

    /* -webkit-transition: opacity 0.1s ease-in-out; */
    
    
    
    pointer-events: none;
    
}




  
    .header{
        
        align-content: left;
        width: 400px;
        
        position: relative;
        margin: 10px;
        z-index: 1;
        
        
       
    }
    .title{
        
        /* font-size: 40px;
        for ui */
        font-size: 38.5px;
        line-height: 50px;
        color: rgb(255, 255, 255);
        font-family: var(--headingfont);
        text-overflow:inherit;
            margin: 0px;
        font-weight: 800;
    }
    .bar{
        
        height: 6px;
        width: 368px;
        
        
        background-color: rgb(255, 255, 255);
        
    }
    .text{
        
        margin-top: 10px;
        font-size: 12px;
        color: rgb(255, 255, 255); 
        text-align: left;
       font-family: var(--headingfont);
       margin-bottom: 35px;
       font-weight: 400;
    }

    .sub-menu a{
        margin-left: 15px;
        text-decoration: none;
        line-height: 0px;
        
        font-size: 20px;
        color: rgb(255, 255, 255);
        font-family: var(--headingfont);
    }
    .sub-menu a:hover{
        font-weight: 800;
    }

.sub-header{
    color: rgba(255, 255, 255, 0.5);       
    float: right;
    z-index: 1;  
    right:0px;
    bottom:10px;
    font-style: italic; 
    position: fixed;
    opacity: 1;
    margin-right: 40px;
    font-size: 0.75em;
    
    }



.project_annotations{
    
    float: right;
    z-index: 1;  
    right:40px;
    top:28px;
    position: fixed; 
    margin-right: 5%;
    font-size: 2em;
    background-color: rgba(0, 0, 0, 0);
    padding: 0;
    border: none;
    font-family: monospace;
    margin: 0px;
    /* border: tomato 1px solid; */
    color: white;
}
.project_annotations .hic_et_nunc{  
    line-height: 2.5;
}
.project_annotations .mail_underline{  
   text-decoration: underline;
    
}


.project_annotations_text{
    font-size: 12px;
    text-align: right;
    /* border: tomato 1px solid; */   
    color: white;
}
.project_mouse_annotations{
    font-size: 12px;
    text-align: right;
}
#cursorX{
    z-index: 1;  
    background-color: rgba(0, 0, 0, 0);
    padding: 0;
    border: none;
    width: 25px;
    color: rgb(255, 255, 255);   
    font-size: 0.75em;
    /* border: tomato 1px solid; */
    }
    #cursorY{
    z-index: 1;  
    background-color: rgba(0, 0, 0, 0);
    padding: 0;
    border: none;
    width: 25px;
    color: rgb(255, 255, 255);
    font-size: 0.75em;
    /* border: tomato 1px solid; */     
    }
    

    .description_border{
        width: 80px;
        /* height: 140px; */
        
        margin: 0px;
        padding: 0px;
        font-family: var(--font);
        line-height: 1em;
        font-size: 12px;
        
        color: rgb(255, 255, 255);
        background-color: rgb(0, 0, 0);
        word-wrap: break-word;
        font-weight: 400;
        
       
    }
    .description_border_only_text{
        width: 300px;
        
        
        margin: 0px;
        padding: 0px;
        font-family: var(--font);
        line-height: 1em;
        font-size: 12px;
        
        color: rgb(255, 255, 255);
        background-color: rgb(0, 0, 0);
        word-wrap: break-word;
        font-weight: 400;
       
       
    }
    .description_border_only_text a{
    color: red;
       
    }


    img{max-width: 100%;}

    @media all and  (max-width: 1000px) {
        .project_annotations{
            opacity: 0;    
        }
        .header{
            width: 80%;
            
            padding: 15px ;
            margin: 15px;
        }
        .header .title{
        font-size: 30px;
        
        }
        .header .bar{
            width: 290px;
        }
        .header .text{
            font-size: 9.4px;
        }
        
    }